<template>
  <div data-app>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body pt-3 pb-0">
        <div class="row">
          <div class="col-md-3">
            <v-autocomplete
              v-model="grupo"
              :items="grupos"
              item-value="id"
              item-text="grupo"
              @change="filterVideos"
              label="Grupo"
              dense
            ></v-autocomplete>
          </div>
        </div>
      </div>
    </div>
    <v-data-table
      :headers="aHeaderVideos"
      :items="aVideosFiltered"
      :sort-desc="true"
      :search="sSearchVideos"
      class="table-striped elevation-1"
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Listado de Contenidos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="sSearchVideos"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2 cursor-pointer"
          v-on:click="openVideo(item)"
          style="font-size: 50px"
        >
          mdi-play
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import {
  GET_VIDEOS,
  GET_VIDEOS_GRUPOS,
} from "@/core/services/store/jobber.module";
export default {
  data() {
    return {
      aHeaderVideos: [
        {
          text: "Grupo",
          value: "grupo",
        },
        {
          text: "Titulo",
          value: "titulo",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      aVideos: [],
      aVideosFiltered: [],
      grupos: [],
      grupo: null,
      sSearchVideos: "",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Contenidos", route: "videos" },
    ]);
  },
  created() {
    let oVue = this;
    oVue.getVideos();
    oVue.getVideosGrupos();
  },
  methods: {
    getVideos() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_VIDEOS).then((aVideos) => {
        oVue.aVideos = oVue.aVideosFiltered = aVideos;
        KTUtil.removeLoading();
      });
    },
    getVideosGrupos() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_VIDEOS_GRUPOS).then((grupos) => {
        grupos.unshift({ id: "", grupo: "" });
        oVue.grupos = grupos;
        KTUtil.removeLoading();
      });
    },
    openVideo(aVideo) {
      window.open(aVideo.url, "_blank");
    },
    filterVideos() {
      let oVue = this;
      if (!oVue.grupo) {
        oVue.aVideosFiltered = oVue.aVideos;
      } else {
        oVue.aVideosFiltered = oVue.aVideos.filter(
          (v) => v.grupo_id === oVue.grupo
        );
      }
    },
  },
};
</script>
